<div>
  <form #fModels="ngForm" (ngSubmit)="onSaveModels(fModels.value)">
    <mat-card>
      <mat-card-header fxLayout="row" fxLayoutAlign="center" class="fmsf-rem-header-margin">
        <div fxLayout="column" fxLayoutAlign="center center">
          <mat-card-title role="heading" aria-level="1">Model Access</mat-card-title>
          <mat-card-subtitle role="heading" aria-level="2">
            For each model type listed, select the number of concurrent models runs desired.<br>
            <!--Users-->
            <ul *ngIf="stateService.user.role_id != UserRole.Admin">
              <li>A Run value of 0 means that model type is disabled.</li>
              <li>A Run value of 1 means that model type is available and a single run can be completed at a time.</li>
              <li>Run values greater than 1 will require Administrator approval. Approval can take up to two working days and an email will be sent when completed.</li>

              <li>Select Request Changes to submit your request. Only auto-approved values will be reflected.</li>
              <li>Select Reset to revert unsaved changes.</li>
            </ul>
            <!--Admins-->
            <ul *ngIf="stateService.user.role_id == UserRole.Admin">
              <li>A Run value of 0 means that model type is disabled.</li>
              <li>Run values greater than 0 mean that model type is available and specifies the number of concurrent model runs allowed.</li>

              <li>Select Request Changes to submit your request.</li>
              <li>Select Reset to revert unsaved changes.</li>
            </ul>
          </mat-card-subtitle>
        </div>
      </mat-card-header>
      <mat-card-content fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="16px">
        <div fxFlex="90%" fxLayout="row" fxLayoutGap="16px">
          <div fxLayout="row wrap" fxLayoutGap="8px"  fxLayoutAlign="center">
            <div *ngFor="let x of userPrivs; let i = index; let first = first">
              <div class="model-layout">
                <span>{{modelTypes[i].id}} - {{modelTypes[i].shortName}}</span>
                <div fxFlex=""></div>
                <mat-form-field floatLabel="always" appearance="outline" class="run-width" fxFlexAlign="end">
                  <mat-label>Runs</mat-label>
                  <input matInput type="number" name="{{x.id.modelType}}" [(ngModel)]="x.concurrentRuns" min="0" max="100">
<!--                  <input matInput type="number" name="{{x.id.modelType}}" [(ngModel)]="x.concurrentRuns" min="0" max="50" appAutoFocus [doFocus]="first">-->
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="note.length > 0">Pending: {{note}}</div>
      </mat-card-content>
      <mat-card-actions fxLayout="row" fxLayoutAlign="center">
        <button mat-raised-button type="submit" color="primary" [disabled]="!fModels.valid || !fModels.dirty || saving">Request Changes</button>
        <button mat-raised-button type="button" color="warn" (click)="onReset()">Reset</button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
